import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SocialMedia from "../components/socialMedia";
import EditIcon from "@material-ui/icons/Edit";
import { checkEmail, login, socialLogin } from "../utils/request";
import { MESSAGES } from "../i18n/messages/constant";
import { FormattedMessage } from "react-intl";
import Alert from "@material-ui/lab/Alert";
import client from "../utils/client";
import redirect from "../utils/redirect";
import CircularProgress from "@material-ui/core/CircularProgress";
import getParam from "../utils/getparam";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: (props) =>
      props.primaryColor ? props.primaryColor : "#E62A43",
    color: (props) => (props.textColor ? props.textColor : "white"),
    fontWeight: "bolder",
    "&:hover, & .Mui-focusVisible": {
      backgroundColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62A43",
      filter: "brightness(80%)",
    },
  },
  linkPage: {
    color: (props) => (props.primaryColor ? props.primaryColor : "#E62A43"),
    fontWeight: "bold",
    textDecoration: "none",
    "@media screen and (max-width: 375px)": {
      fontSize: "12px",
    },
    "@media screen and (max-width: 320px)": {
      fontSize: "11px",
    },
  },
  input: {
    "& label.Mui-focused": {
      color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62C46",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c4c4c4",
      },
      "&:hover fieldset": {
        borderColor: "#5b5b5b",
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.primaryColor ? props.primaryColor : "#E62C46",
      },
    },
  },
  title: {
    color: "grey",
    marginTop: "10px",
    fontSize: "15px",
    fontWeight: "bold",
  },
  email: {
    color: "grey",
  },
  emailDisplay: {
    padding: "0px 15px 0px 15px",
  },
  errorText: {
    color: "red",
    marginLeft: "10px",
  },
  socialErrorText: {
    color: "red",
    marginTop: "10px",
  },
  terms: {
    color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    textDecoration: "none",
    "@media screen and (max-width: 375px)": {
      fontSize: "12px",
    },
  },
}));

export default function LoginPage({ history, config = {} }) {
  const classes = useStyles(config.style);
  const [continueProcess, setContinue] = React.useState(false);
  const [showPass, setShowpass] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorPass, setErrorPass] = React.useState(false);
  const [errorSocial, setErrorSocial] = React.useState(false);
  const [regris, setRegris] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorGrant, setErrorGrant] = React.useState(false);

  React.useEffect(() => {
    initState();
    googleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initState = () => {
    const { state } = history.location;
    console.log(history);
    if (state) {
      setRegris(state.registerSuccess);
    }
  };

  async function googleLogin() {
    const code = getParam("code");
    const socmed = getParam("prompt") ? "google" : "facebook";
    if (!code) {
      return;
    } else {
      setLoading(true);

      const loginRes = await client.auth.login(socmed, {
        callback: `${window.location.origin}/login`,
        code,
      });

      console.log(loginRes);

      if (!loginRes.data) {
        setLoading(false);
        setErrorGrant(true);
        return console.log("error login");
      }
      if (loginRes.data.type === "LoginExisting") {
        console.log("loginexist");
        const check = await client.user.get();
        // window.location.assign("/");
        await redirect(check);
        setLoading(false);

        return;
      }

      const loginAttr = loginRes.data;

      //   Create a new user based on the social account.
      const registerRes = await client.auth.register(socmed, {
        oauthKey: loginAttr.oauthKey,
        email: loginAttr.email,
        extras: {
          name: loginAttr.name,
        },
      });

      console.log(registerRes);

      if (!registerRes.data) return console.log("error register");
      if (registerRes.data.type === "Profile") {
        client.auth.redoOAuth(socmed);
      }
    }
    setLoading(false);
  }

  const clearErrors = () => {
    setError(false);
    setErrorPass(false);
    setErrorSocial(false);
    setErrorGrant(false);
  };

  const handleContinue = async () => {
    clearErrors();
    setRegris(false);
    setLoading(true);

    history.replace("/login", { registerSuccess: false });
    if (email === "") {
      setError(true);
    } else {
      const check = await checkEmail(config.serverOidcUrl, email);
      if (check === "ERROR") {
        setError(true);
      } else if (check) {
        setError(false);
        setContinue(!continueProcess);
      } else {
        history.push({
          pathname: "/register",
          state: { email },
        });
      }
    }
    setLoading(false);
  };

  const editEmail = () => {
    setContinue(!continueProcess);
  };

  const handleLogin = async () => {
    clearErrors();
    setLoading(true);
    const check = await login(config.serverSubmitUrl, email, password);
    console.log(check);
    if (check.data) {
      await redirect(check);
    } else if (check.error.status === "401") {
      setErrorPass(true);
      setErrorText(check.error.title);
    } else {
      setErrorPass(true);
      setErrorText(<FormattedMessage id={MESSAGES.PAGES.LOGIN.ERROR.WINDOW} />);
    }
    setLoading(false);
  };

  const handleClickShowPassword = () => {
    setShowpass(!showPass);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleOauthCallback = async (cbRes) => {
    clearErrors();

    if (cbRes.userExists === true) {
      const check = await socialLogin(
        config.serverSubmitUrl,
        cbRes.provider,
        cbRes.oauthKey
      );

      if (check.data.redirectTo) {
        setErrorSocial(false);
        window.location.assign(check.data.redirectTo);
      } else if (check.status === 401) {
        setErrorSocial(true);
        setErrorText(check.data.message);
      } else {
        setErrorSocial(true);
        setErrorText(
          <FormattedMessage id={MESSAGES.PAGES.LOGIN.ERROR.WINDOW} />
        );
      }
    } else {
      history.push({
        pathname: "/register",
        state: {
          socmed: {
            provider: cbRes.provider,
            oauthKey: cbRes.oauthKey,
          },
          email: cbRes.email,
        },
      });
    }
  };

  return (
    <div>
      <FormattedMessage
        id={MESSAGES.PAGES.LOGIN.TITLE}
        values={{ name: config.name }}
      >
        {(message) => {
          if (document.title !== message) {
            document.title = message;
          }

          return null;
        }}
      </FormattedMessage>
      <Grid container justify="center">
        <Grid item>
          {/* <div className={classes.title}>Log in to your account</div> */}
          <div className={classes.title}>
            <FormattedMessage id={MESSAGES.PAGES.LOGIN.HEADER} />
          </div>
        </Grid>
      </Grid>
      <form
        className={classes.form}
        action={config.serverSubmitUrl + "/login"}
        method="post"
        id="form_login"
      >
        <Grid container spacing={2}>
          {continueProcess ? (
            <Grid item xs={12} style={{ padding: "0px 10px 0px 20px" }}>
              <Grid container justify="space-between" direction="row">
                <Grid item xs={8} style={{ paddingTop: "15px" }}>
                  <span className={classes.email}>{email}</span>
                </Grid>
                <Grid item>
                  <IconButton aria-label="edit" onClick={editEmail}>
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {regris && (
                <Alert severity="success" style={{ marginBottom: "10px" }}>
                  Register success try to login
                </Alert>
              )}
              {errorGrant && (
                <Alert severity="error" style={{ marginBottom: "10px" }}>
                  Invalid code social media, try again.
                </Alert>
              )}
              <TextField
                variant="outlined"
                error={error}
                required
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                onChange={(e) => handleEmail(e)}
                value={email}
                fullWidth
                id="email"
                label={
                  <FormattedMessage id={MESSAGES.PAGES.LOGIN.LABEL.EMAIL} />
                }
                name="email"
                className={classes.input}
              />
            </Grid>
          )}
          <input type="hidden" value={email} name="email" />
          {error && <span className={classes.errorText}>Invalid user.</span>}
          {continueProcess && (
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.input}
                fullWidth
                required
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  <FormattedMessage id={MESSAGES.PAGES.LOGIN.LABEL.PASSWORD} />
                </InputLabel>
                <OutlinedInput
                  type={showPass ? "text" : "password"}
                  name="password"
                  label={
                    <FormattedMessage
                      id={MESSAGES.PAGES.LOGIN.LABEL.PASSWORD}
                    />
                  }
                  id="password"
                  error={errorPass}
                  value={password}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  onChange={(e) => handlePassword(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          )}
          {errorPass && <div className={classes.errorText}>{errorText}</div>}
        </Grid>
        <Button
          fullWidth
          variant="contained"
          // color="inherit"
          disabled={loading}
          className={classes.submit}
          onClick={() => (continueProcess ? handleLogin() : handleContinue())}
        >
          {loading ? (
            <CircularProgress style={{ width: "20px", height: "20px" }} />
          ) : continueProcess ? (
            <FormattedMessage id={MESSAGES.BUTTON.LOGIN} />
          ) : (
            <FormattedMessage id={MESSAGES.BUTTON.CONTINUE} />
          )}
        </Button>
        <SocialMedia config={config} onOauthCallback={handleOauthCallback} />
        {errorSocial && (
          <div className={classes.socialErrorText}>{errorText}</div>
        )}
        <Divider style={{ marginTop: "20px" }} />
      </form>
      <Grid
        container
        justify="space-between"
        direction="row"
        style={{ padding: "20px 20px 0px 20px" }}
      >
        <Grid item>
          <Link
            to="/forgot-password"
            variant="body2"
            className={classes.linkPage}
          >
            <FormattedMessage id={MESSAGES.LINK.FORGOTPWD} />
          </Link>
        </Grid>
        <Grid item>
          <Link to="/register" className={classes.linkPage}>
            <FormattedMessage id={MESSAGES.LINK.CREATENEW} />
          </Link>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ margin: "10px 0px 20px 0px" }}>
        <Grid item>
          <a href="/#" className={classes.linkPage}>
            <FormattedMessage id={MESSAGES.LINK.TERMS} />
          </a>
        </Grid>
      </Grid>
    </div>
  );
}
