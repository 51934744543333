const testEnv = {
  apiUrl: "https://mooc-test-api.hash.id",
  studentWeb: "https://mooc-test.hash.id",
  staffWeb: "https://develop--greatchain-office.netlify.app",
};

const productionEnv = {
  apiUrl: "https://core.greatchain.academy",
  studentWeb: "https://greatchain.academy",
  staffWeb: "https://office.greatchain.academy",
};

export const isProduction =
  window.location.host === "auth.greatchain.academy" ||
  window.location.host === "greatchain-auth.netlify.app";

const validEnv = isProduction ? productionEnv : testEnv;

export default validEnv;
