import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

function booleanFromString(val) {
  const truePattern = ["true", "yes", "1"];
  const lowerVal = val
    .toString()
    .toLowerCase()
    .trim();
  if (truePattern.includes(lowerVal)) return true;
  return false;
}

export default function Boolean({
  name,
  value,
  onBooleanChange,
  allowNull,
  className,
  column
}) {
  const [myBoolean, setMyBoolean] = useState("");
  useEffect(() => {
    if (value === true) {
      setMyBoolean("true");
    }
    if (value === false) {
      setMyBoolean("false");
    }
    if (value === null) {
      setMyBoolean("");
    }
    // console.log("boooooolean" + value);
  }, [value]);
  const handleBooleanChange = event => {
    setMyBoolean(event.target.value);
    if (event.target.value === "") {
      onBooleanChange({
        name: column ? column : event.target.name,
        value: null
      });
    } else {
      onBooleanChange({
        name: column ? column : event.target.name,
        value: booleanFromString(event.target.value)
      });
    }
  };
  return (
    <TextField
      name={column}
      label={name}
      id="outlined-select-boolean"
      select
      fullWidth
      className={className}
      value={myBoolean}
      variant="outlined"
      onChange={handleBooleanChange}
      required={allowNull}
    >
      <MenuItem key="true" value="true">
        True
      </MenuItem>
      <MenuItem key="false" value="false">
        False
      </MenuItem>
    </TextField>
  );
}
