import isProd from "./variabel";
const redirect = async (check) => {
  let redirect;
  const at = localStorage.getItem("MBaaS.auth.accessToken");
  const rt = localStorage.getItem("MBaaS.auth.refreshToken");
  localStorage.removeItem("MBaaS.auth.accessToken");
  localStorage.removeItem("MBaaS.auth.refreshToken");
  if (
    check.data.roles.includes("Administrator") ||
    check.data.roles.includes("Staff")
  ) {
    // console.log("admin staff");
    if (window.location.host === "localhost:8080") {
      redirect = `http://localhost:8081/login?at=${at}&rt=${rt}`;
    } else {
      redirect = `${isProd.staffWeb}/login?at=${at}&rt=${rt}`;
    }
  } else {
    // console.log("student tutor");
    if (window.location.host === "localhost:8080") {
      redirect = `http://localhost:3000/verify?at=${at}&rt=${rt}`;
    } else {
      redirect = `${isProd.studentWeb}/verify?at=${at}&rt=${rt}`;
    }
  }
  window.location.assign(redirect);
};

export default redirect;
