import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles/";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
// import { initOauth } from "../utils/request";
import { MESSAGES } from "../i18n/messages/constant";
import { FormattedMessage } from "react-intl";
import client from "../utils/client";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  socialMedia: {
    marginTop: "15px",
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    "@media screen and (max-width: 540px)": {
      fontSize: "12px",
    },
    "@media screen and (max-width: 375px)": {
      fontSize: "10px",
    },
    loading: {
      color: (props) => (props.primaryColor ? props.primaryColor : "#E62A43"),
      margin: "50px 0px 50px 0px",
    },
  },
}));

export default function SocialMedia({ config = {}, onOauthCallback }) {
  const classes = useStyles(config.style);
  const [loading, setLoading] = React.useState(false);

  const handleInitOauth = async (provider) => {
    setLoading(true);
    // console.log(`${window.location.origin}/login`);
    // const initRes = await initOauth(config.serverOidcUrl, provider);
    const loginsosmed = await client.auth.oauthRedirect(
      provider,
      `${window.location.origin}/login`
    );
    console.log(loginsosmed);
    setLoading(true);
    // if (initRes.provider !== provider) return;

    // let oauthWindow;

    // window.oauthCallback = (cbRes) => {
    //   // console.log(cbRes);
    //   oauthWindow.close();

    //   if (typeof onOauthCallback === "function") onOauthCallback(cbRes);
    // };

    // oauthWindow = window.open(
    //   initRes.url,
    //   "OAuthWindow",
    //   "width=600,height=570"
    // );
  };

  return (
    <div>
      <Grid container justify="center">
        <Grid item>
          {(config.data.socialLogins.google ||
            config.data.socialLogins.facebok ||
            config.data.socialLogins.twitter) && (
            <div style={{ color: "grey" }}>OR</div>
          )}
        </Grid>
      </Grid>
      {loading ? (
        <Grid container justify="center">
          <Grid item>
            <CircularProgress style={{ marginTop: "20px" }} size={60} />
          </Grid>
        </Grid>
      ) : (
        <div>
          {config.data.socialLogins.google && (
            <Button
              fullWidth
              variant="contained"
              startIcon={
                <img
                  src={"google.png"}
                  alt="google"
                  style={{ height: "15px" }}
                />
              }
              className={classes.socialMedia}
              onClick={() => handleInitOauth("google")}
            >
              <FormattedMessage
                id={MESSAGES.BUTTON.SOCIALMEDIA.CONTINUE.GOOGLE}
              />
            </Button>
          )}
          {config.data.socialLogins.facebook && (
            <Button
              fullWidth
              variant="contained"
              startIcon={<FacebookIcon style={{ color: "#4267B2" }} />}
              className={classes.socialMedia}
              onClick={() => handleInitOauth("facebook")}
            >
              <FormattedMessage
                id={MESSAGES.BUTTON.SOCIALMEDIA.CONTINUE.FACEBOOK}
              />
            </Button>
          )}
          {config.data.socialLogins.twitter && (
            <Button
              fullWidth
              variant="contained"
              startIcon={<TwitterIcon style={{ color: "#50ABF1" }} />}
              className={classes.socialMedia}
              onClick={() => handleInitOauth("twitter")}
            >
              <FormattedMessage
                id={MESSAGES.BUTTON.SOCIALMEDIA.CONTINUE.TWITTER}
              />
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
