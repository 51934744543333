import React from "react";
import TextField from "@material-ui/core/TextField";

export default function Date({
  name,
  value,
  onDateChange,
  allowNull,
  clearValue,
  className,
  column
}) {
  const handleDateChange = date => {
    const nameSave = column ? column : name;
    onDateChange({ name: nameSave, value: date.target.value });
  };

  return (
    <TextField
      id="date"
      label={name}
      fullWidth
      type="date"
      value={value}
      onChange={handleDateChange}
      className={className}
      InputLabelProps={{
        shrink: true
      }}
      required={allowNull}
    />
  );
}
