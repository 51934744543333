/* eslint-disable no-useless-computed-key */
import React, { useContext, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LoginPage from "./pages/loginPage";
import ForgotPassword from "./pages/forgotPassword";
import Register from "./pages/register";
import Update from "./pages/update";
import ChangePassword from "./pages/changePassword";
// import LinkSosial from "./pages/linkMedia";
// import Auth from "./pages/authAccess";
// import AuthApp from "./pages/authApplication";
import ResetPassword from "./pages/resetPassword";
// import Profile from "./pages/profile";
import EmailVerified from "./pages/emailVerified";
// import ViewAccount from "./pages/viewAccount";
// import NotAuth from "./pages/notAuth";
// import Authorize from "./pages/authorize";
// import SelectAccount from "./pages/selectAccount";
import LangButton from "./components/LangButton";
import { LocaleContext } from "./context/contexts/LocaleContext";
import { I18nProvider } from "./i18n";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    minHeight: "640px",
    ["@media screen and (max-width: 540px)"]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      minHeight: "100vh",
    },
    padding: "10px 20px 20px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textLogo: {
    color: (props) => (props.primaryColor ? props.primaryColor : "#F2562B"),
    fontWeight: "bolder",
  },
  imgLogo: {
    width: "100px",
    objectFit: "contain",
  },
  footerLogo: {
    width: "50px",
    objectFit: "contain",
  },
  divLogo: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "5px",
  },
  content: {
    minHeight: "500px",
    width: "100%",
  },
  footer: {
    marginTop: "20px",
    width: "100%",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "5px",
  },
  selectLanguage: {
    marginTop: "5px",
  },
}));

function App({ config = {} }) {
  const classes = useStyles(config.style);
  const { locale, dispatch } = useContext(LocaleContext);

  useEffect(() => {
    if (!["en", "id"].includes(locale) && ["en", "id"].includes(config.lang)) {
      dispatch({ type: "CHANGE_LOCALE", value: config.lang });
    }
  }, [config.lang, dispatch, locale]);

  const changeLocale = (value) => {
    dispatch({ type: "CHANGE_AND_SAVE_LOCALE", value });
  };

  function Footer() {
    return (
      <div className={classes.footer}>
        <Divider style={{ marginBottom: "10px" }} />
        <div className={classes.divLogo}>
          {config.style.logoUrl ? (
            <img
              src={config.style.logoUrl}
              alt="logo"
              className={classes.footerLogo}
            />
          ) : (
            <Typography variant="h5" className={classes.textLogo}>
              {config.name}
            </Typography>
          )}
        </div>
        <Typography variant="body2" color="textSecondary" align="center">
          {config.style.footerText
            ? config.style.footerText
            : "Mitra inovasi teknologi dan distribusi konten digital"}
        </Typography>
      </div>
    );
  }

  function Header() {
    return (
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <div className={classes.selectLanguage}>
                <LangButton
                  config={config}
                  value={locale}
                  onChangeValue={(value) => {
                    changeLocale(value);
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <div className={classes.header}>
                <div className={classes.header}>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    {config.style.logoUrl ? (
                      <img
                        src={config.style.logoUrl}
                        alt="logo"
                        className={classes.imgLogo}
                      />
                    ) : (
                      <Typography variant="h3" className={classes.textLogo}>
                        {config.name}
                      </Typography>
                    )}
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <I18nProvider locale={locale || "en"}>
      <Container component="main" maxWidth="xs" style={{ padding: "0px" }}>
        <CssBaseline />
        <Paper elevation={3} className={classes.paper}>
          <Router basename={config.basePath}>
            {config.defaultPath === "authorize" ? "" : <Header />}
            <div className={classes.content}>
              <Switch>
                <Redirect from="/" exact to={config.defaultPath} />
                <Route
                  exact
                  path="/login"
                  component={(props) => (
                    <LoginPage {...props} config={config} />
                  )}
                />
                <Route
                  path="/forgot-password"
                  component={() => <ForgotPassword config={config} />}
                />
                <Route
                  path="/register"
                  component={(props) => <Register {...props} config={config} />}
                />
                <Route
                  path="/update"
                  component={(props) => <Update {...props} config={config} />}
                />
                <Route
                  path="/change-password"
                  component={(props) => (
                    <ChangePassword {...props} config={config} />
                  )}
                />
                <Route
                  path="/reset-password"
                  component={(props) => (
                    <ResetPassword {...props} config={config} />
                  )}
                />
                {/* <Route
                  path="/link-social-accounts"
                  component={(props) => (
                    <LinkSosial {...props} config={config} />
                  )}
                /> */}
                {/* <Route
                  path="/view-authorized-access"
                  component={(props) => <Auth {...props} config={config} />}
                />
                <Route
                  path="/auth-app"
                  component={(props) => <AuthApp {...props} config={config} />}
                /> */}
                {/* <Route
                  path="/manage-account"
                  component={(props) => (
                    <ViewAccount {...props} config={config} />
                  )}
                />
                <Route
                  path="/view-profile"
                  component={(props) => <Profile {...props} config={config} />}
                /> */}
                <Route
                  path="/email-verified"
                  component={(props) => (
                    <EmailVerified {...props} config={config} />
                  )}
                />
                {/* <Route
                  path="/unauthorized"
                  component={(props) => <NotAuth {...props} config={config} />}
                />
                <Route
                  path="/authorize"
                  component={(props) => (
                    <Authorize {...props} config={config} />
                  )}
                /> */}
                {/* <Route
                  exact
                  path="/select_account"
                  component={(props) => (
                    <SelectAccount {...props} config={config} />
                  )}
                /> */}
              </Switch>
            </div>
            {config.defaultPath === "authorize" ? "" : <Footer />}
          </Router>
        </Paper>
      </Container>
    </I18nProvider>
  );
}

// const wrapApp = withRouter(App);
export default App;
