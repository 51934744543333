import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(theme => ({
  socialMedia: {
    marginTop: "15px",
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    "@media screen and (max-width: 540px)": {
      fontSize: "12px"
    },
    "@media screen and (max-width: 375px)": {
      fontSize: "10px"
    }
  },
  progress: {
    marginTop: "5px"
  },
  low: {
    "& .MuiLinearProgress-bar	": {
      backgroundColor: "#F1646C"
    }
  },
  fair: {
    "& .MuiLinearProgress-bar	": {
      backgroundColor: "#eaea67"
    }
  },
  good: {
    "& .MuiLinearProgress-bar	": {
      backgroundColor: "#4fc3ea"
    }
  },
  excellent: {
    "& .MuiLinearProgress-bar	": {
      backgroundColor: "#7cd3b2"
    }
  },
  wording: {
    color: "grey",
    marginTop: "5px",
    textAlign: "center"
  }
}));

export default function passwordBar({ pass }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();

  return (
    <div>
      <div>
        <LinearProgress
          variant="determinate"
          value={pass.value * 25}
          className={`${
            pass.value === 1
              ? classes.low
              : pass.value === 2
              ? classes.fair
              : pass.value === 3
              ? classes.good
              : classes.excellent
          } ${classes.progress}`}
        />
      </div>
      <div className={classes.wording}>{pass.level}</div>
    </div>
  );
}
