import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
const useStyles = makeStyles((theme) => ({
  linkPage: {
    color: (props) => (props.primaryColor ? props.primaryColor : "#E62A43"),
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "none",
    "@media screen and (max-width: 375px)": {
      fontSize: "12px",
    },
    "@media screen and (max-width: 320px)": {
      fontSize: "11px",
    },
  },
}));

export default function SimpleMenu({ config, value, onChangeValue }) {
  const classes = useStyles(config.style);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    if (typeof value === "string") onChangeValue(value);
    setAnchorEl(null);
  };

  return (
    <div>
      <Link className={classes.linkPage} onClick={handleClick}>
        {value.toUpperCase() || "EN"}
      </Link>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose("en")}>English</MenuItem>
        <MenuItem onClick={() => handleClose("id")}>Bahasa</MenuItem>
      </Menu>
    </div>
  );
}
