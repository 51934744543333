import axios from "axios";
import client from "./client";
export function request(method, url, { params, data } = {}) {
  return axios({
    method,
    url,
    params,
    data,
  });
}

// ----------
// OIDC
// ----------

export async function checkEmail(baseURL, email) {
  if (email !== "") {
    try {
      // const url = `${baseURL}/action/check-email`;
      // const { data } = await request("POST", url, {
      //   data: { email },
      // });
      const check = await client.auth.checkEmail(email);
      return check.data.registered;
    } catch (error) {
      console.log("error");
      return "ERROR";
    }
  }
}

export async function initOauth(baseURL, provider) {
  if (provider !== "") {
    try {
      const url = `${baseURL}/action/init-oauth`;
      const { data } = await request("POST", url, {
        data: { provider },
      });
      return data.data;
    } catch (error) {
      console.log("error");
      return "ERROR";
    }
  }
}

export async function login(baseURL, email, password) {
  try {
    // const url = `${baseURL}/login`;
    // const { data } = await request("POST", url, {
    //   data: { email, password },
    // });
    const login = await client.auth.login("local", {
      email,
      password,
    });

    // console.log(data);
    return login;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function signup(baseURL, reqBody) {
  try {
    // const url = `${baseURL}/signup`;
    // const { data } = await request("POST", url, { data: reqBody });
    const signup = await client.auth.register("local", reqBody);
    console.log(signup);
    return signup;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function socialLogin(baseURL, provider, oauthKey) {
  try {
    const url = `${baseURL}/social-login`;
    const { data } = await request("POST", url, {
      data: { provider, oauthKey },
    });
    return data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function socialSignup(baseURL, reqBody) {
  try {
    const url = `${baseURL}/social-signup`;
    const { data } = await request("POST", url, { data: reqBody });
    return data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function forgot(baseURL, email) {
  try {
    // const url = `${baseURL}/action/forgot-pwd`;
    // const { data } = await request("POST", url, {
    //   data: { email },
    // });
    const forgot = await client.auth.forgotPassword(email);
    // console.log(data);
    console.log(forgot);
    return forgot;
  } catch (error) {
    console.log(error.response.data);

    return error.response.data;
  }
}

export async function reset(baseURL, reqBody) {
  try {
    // const url = `${baseURL}/action/reset-pwd`;
    // const { data } = await request("POST", url, { data: reqBody });
    const reset = await client.auth.resetPassword(
      reqBody.token,
      reqBody.password
    );
    console.log(reset);
    return reset;
  } catch (error) {
    return error.response.data;
  }
}

export async function accountContinue(baseURL) {
  try {
    const url = `${baseURL}/continue`;
    const { data } = await request("POST", url);
    // console.log(data);
    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function accountSwitch(baseURL) {
  try {
    const url = `${baseURL}/continue`;
    const { data } = await request("POST", url, { data: { switch: true } });
    // console.log(data);
    return data;
  } catch (error) {
    return error.response.data;
  }
}

// ----------
// Account
// ----------

function authorize(response) {
  if (response.status === 401) {
    window.location.assign(response.data.data.oauthUrl);
    return;
  }

  return response;
}

export async function getProfile(baseURL) {
  try {
    /* const mock = {
      data: {
        id: "28dc27d3-8a08-4698-b24c-23e233c533a0",
        created_at: "2020-04-01T19:46:00.000Z",
        updated_at: "2020-04-03T07:16:40.144Z",
        roles: ["Administrator", "Reader"],
        email: "admin@mylib.id",
        social_ids: {
          google: null,
          twitter: null,
          facebook: null,
        },
        verified: true,
        fcm_tokens: [],
        name: "admin-MOCK",
        country: "MOCK-COUNTRY",
        address: "MOCK-ADDRESS",
        deactivated_at: null,
      },
    }; */
    const url = `${baseURL}/get-profile`;
    const { data } = await request("POST", url);
    // const data = mock;

    // console.log(data);
    return data;
  } catch (error) {
    return authorize(error.response);
  }
}

export async function updateProfile(baseURL, reqBody) {
  try {
    const url = `${baseURL}/update-profile`;
    const { data } = await request("POST", url, { data: reqBody });
    // console.log(data);
    return data;
  } catch (error) {
    return authorize(error.response);
  }
}

export async function changePassword(baseURL, reqBody) {
  try {
    const url = `${baseURL}/update-password`;
    const { data } = await request("POST", url, { data: reqBody });
    // console.log(data);
    return data;
  } catch (error) {
    return authorize(error.response);
  }
}

export async function linkSocial(baseURL, provider, oauthKey) {
  try {
    const url = `${baseURL}/link-social`;
    const { data } = await request("POST", url, {
      data: { provider, oauthKey },
    });
    return data;
  } catch (error) {
    return authorize(error.response);
  }
}

export async function unlinkSocial(baseURL, provider) {
  try {
    const url = `${baseURL}/unlink-social`;
    const { data } = await request("POST", url, {
      data: { provider },
    });
    return data;
  } catch (error) {
    return authorize(error.response);
  }
}

export async function getAccess(baseURL) {
  try {
    const url = `${baseURL}/get-authorized`;
    const { data } = await request("POST", url);
    // console.log(data);
    return data;
  } catch (error) {
    return authorize(error.response);
  }
}

export async function revokeAccess(baseURL, reqBody) {
  try {
    const url = `${baseURL}/revoke-authorized`;
    const { data } = await request("POST", url, { data: reqBody });
    // console.log(data);
    return data;
  } catch (error) {
    return authorize(error.response);
  }
}

export async function confirmAccess(baseURL) {
  try {
    const url = `${baseURL}/confirm`;
    const { data } = await request("POST", url);
    console.log(data);
    return data;
  } catch (error) {
    return authorize(error.response);
  }
}
