import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default function Jsonb({
  name,
  value,
  onJsonbChange,
  allowNull,
  onEnableSave,
  table,
  className,
  column
}) {
  const [strfirebase, setStrfirebase] = useState("");

  useEffect(() => {
    console.log(value);

    setStrfirebase(JSON.stringify(value, null, 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (IsJsonString(strfirebase)) {
  //     onJsonbChange({
  //       name,
  //       value: JSON.parse(strfirebase)
  //     });
  //     // onEnableSave(true);
  //   } else {
  //     // onEnableSave(false);
  //   }
  // }, [name, onEnableSave, onJsonbChange, strfirebase]);

  return (
    <FormControl fullWidth>
      <TextField
        id="outlined-firebase"
        label={name}
        name={column}
        fullWidth
        className={className}
        variant="outlined"
        value={strfirebase}
        multiline
        rows={8}
        rowsMax={8}
        error={IsJsonString(strfirebase) === false}
        helperText={IsJsonString(strfirebase) === false ? "JSON Invalid" : ""}
        onChange={e => {
          setStrfirebase(e.target.value);
        }}
        required={allowNull}
      />
    </FormControl>
  );
}
