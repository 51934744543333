import React from "react";
import TextField from "@material-ui/core/TextField";

export default function Text({
  name,
  value,
  onChange,
  allowNull,
  className,
  column
}) {
  const handleChange = event => {
    if (event.target.value === "") {
      onChange({
        name: event.target.name,
        value: null
      });
    } else {
      onChange({
        name: event.target.name,
        value: event.target.value
      });
    }
  };
  return (
    <TextField
      id="outlined-textfield"
      label={name}
      fullWidth
      className={className}
      multiline
      rows="4"
      name={column}
      value={value === null ? "" : value}
      variant="outlined"
      onChange={handleChange}
      inputProps={{
        maxLength: 3000
      }}
      required={allowNull}
    />
  );
}
