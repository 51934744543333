import React, { createContext, useReducer } from "react";
import { localeReducer } from "../reducers/localeReducer";

export const LocaleContext = createContext();
const LocaleContextProvider = (props) => {
  const [locale, dispatch] = useReducer(localeReducer, true, () => {
    const localData = localStorage.getItem("locale");
    return localData ? JSON.parse(localData) : "";
  });
  return (
    <LocaleContext.Provider value={{ locale, dispatch }}>
      {props.children}
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;
