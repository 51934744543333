import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import LocaleContextProvider from "./context/contexts/LocaleContext";

// class Auth {
//   constructor(props) {
//     const defaultConfig = {
//       name: "test-project",
//       baseURL: "http://localhost:3000",
//       basePath: "/idfs/randomid",
//       cors: "*",
//       lang: "",
//       defaultPath: "/login",
//       data: {
//         extras: [],
//         client: {
//           clientName: "",
//           clientUri: "",
//           logoUri: "",
//           policyUri: "",
//           tosUri: "",
//           redirectUri: "",
//         },
//         user: {
//           name: "",
//           email: "",
//         },
//         scope: "",
//       },
//     };
//     this.config = Object.assign(defaultConfig, props);
//   }

//   render() {
//     const newElem = document.createElement("div");
//     const randomId = Math.floor(Math.random() * Date.now()).toString(32);
//     newElem.setAttribute("id", randomId);
//     document.body.appendChild(newElem);
//     const rootElement = document.getElementById(randomId);
//     if (rootElement) {
//       ReactDOM.render(
//         <LocaleContextProvider>
//           <App config={this.config} />
//         </LocaleContextProvider>,
//         document.getElementById(randomId)
//       );
//     } else {
//       throw new Error("Could not find root element");
//     }
//   }
// }

// module.exports = Auth;

const defaultConfig = {
  name: "Greatchain academy",
  serverOidcUrl: "http://localhost:3000/idfs/oidc",
  serverAccountUrl: "http://localhost:3000/idfs/account",
  serverSubmitUrl:
    "http://localhost:3000/idfs/oidc/interaction/yvsD21BscbsWf45",
  basePath: "/",
  defaultPath: "/login",
  cors: "*",
  lang: "",
  data: {
    fieldLabels: { email: "Email", name: "Nama" },
    signupFields: [
      {
        name: "name",
        type: "string",
        defaultValue: "",
        label: "Nama",
        allowNull: false,
      },
    ],
    socialLogins: {
      google: true,
      facebook: true,
      twitter: false,
    },
    updateProfileFields: [
      { field: "email" },
      { allowUpdate: true, field: "name" },
    ],
    extras: [
      {
        name: "alamat",
        type: "string",
        defaultValue: "ini string",
        allowNull: false,
      },
      {
        name: "pekerjaan",
        type: "string",
        defaultValue: "ini string",
        allowNull: false,
      },
    ],
    oauthUrl: "https://otakudesu.org/",
    client: {
      clientName: "Apenjer App",
      clientUri: "http://client-123.pro",
      logoUri:
        "https://jatenglive.com/images/news/4-Logo-Ini-Punya-Arti-Tersembunyi-news20180823-cutting%20stickers%20shop.jpg",
      policyUri: "http://client-123.pro/policy",
      tosUri: "http://client-123.pro/tos",
      redirectUri: "",
    },
    user: {
      name: "user-idfs",
      email: "email-idfs@mail.to",
    },
    scope:
      "Nama, alamat, email dan data pribadi lainnya. Melakukan pengaturan terhadap akun anda. Mengirim Laporan bulanan ke email anda.",
  },
  style: {
    primaryColor: "#1737a7",
    colorText: "#FFFFFF",
    footerText: "Greatchain Academy -- Lingkar Indonesia Unggul",
    logoUrl: "https://mooc-common.sgp1.digitaloceanspaces.com/gca_bar3.png",
  },
  ui: {
    fieldLabels: { email: "Email", name: "Nama" },
    footerText: "Greatchain Academy -- Lingkar Indonesia Unggul",
    logoUrl: "https://mooc-common.sgp1.digitaloceanspaces.com/gca_bar3.png",
    primaryColor: "#1737a7",
    signupFields: [{ field: "name" }],
    textColor: "#FFFFFF",
    updateProfileFields: [
      { field: "email" },
      { allowUpdate: true, field: "name" },
    ],
  },
};

ReactDOM.render(
  <LocaleContextProvider>
    <App config={defaultConfig} />
  </LocaleContextProvider>,
  document.getElementById("root")
);

//biru #00afce
//hijau #00cca6
//merah #cc2100
