/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { forgot } from "../utils/request";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { MESSAGES } from "../i18n/messages/constant";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: (props) =>
      props.primaryColor ? props.primaryColor : "#E62A43",
    color: (props) => (props.textColor ? props.textColor : "white"),
    fontWeight: "bolder",
    "&:hover, & .Mui-focusVisible": {
      backgroundColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62A43",
      filter: "brightness(80%)",
    },
  },
  linkPage: {
    color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    fontWeight: "bold",
    textDecoration: "none",
  },
  input: {
    "& label.Mui-focused": {
      color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62C46",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c4c4c4",
      },
      "&:hover fieldset": {
        borderColor: "#5b5b5b",
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.primaryColor ? props.primaryColor : "#E62C46",
      },
    },
  },
  errorText: {
    color: "red",
    marginLeft: "10px",
  },
  success: {
    marginLeft: "10px",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    marginTop: "10px",
    alignItems: "center",
  },
  loading: {
    color: (props) => (props.textColor ? props.textColor : "white"),
  },
}));

export default function forgotPassword({ props, config = {} }) {
  const classes = useStyles(config.style);
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handlerContinue = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    const result = await forgot(config.serverOidcUrl, email);
    console.log(result);

    if (result === null) {
      setError(false);
      setSuccess(true);
      setEmail("");
    } else {
      if (result.error.title === "'email' is not valid") {
        setErrorMessage(
          <FormattedMessage
            id={MESSAGES.PAGES.FORGOTPASSWORD.MESSAGE.ERROR.INVALID}
          />
        );
      } else {
        setErrorMessage(
          <FormattedMessage
            id={MESSAGES.PAGES.FORGOTPASSWORD.MESSAGE.ERROR.FORMAT}
          />
        );
      }
      setError(true);
    }
    setLoading(false);
  };

  // React.useEffect(() => {
  //   document.title = config.name + " - Forgot Password";
  // }, [config.name]);

  return (
    <div>
      <FormattedMessage
        id={MESSAGES.PAGES.FORGOTPASSWORD.TITLE}
        values={{ name: config.name }}
      >
        {(message) => {
          if (document.title !== message) {
            document.title = message;
          }

          return null;
        }}
      </FormattedMessage>
      <Grid container justify="center">
        <Grid item>
          <div
            style={{
              color: "grey",
              marginTop: "10px",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id={MESSAGES.PAGES.FORGOTPASSWORD.HEADER} />
          </div>
        </Grid>
      </Grid>
      <form className={classes.form} onSubmit={(e) => handlerContinue(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              error={error}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label={
                <FormattedMessage
                  id={MESSAGES.PAGES.FORGOTPASSWORD.LABEL.EMAIL}
                />
              }
              name="email"
              className={classes.input}
            />
            {error && <span className={classes.errorText}>{errorMessage}</span>}
            {success && (
              <div className={classes.success}>
                <div>
                  <FormattedMessage
                    id={MESSAGES.PAGES.FORGOTPASSWORD.MESSAGE.SUCCESS}
                  />
                </div>
                <div style={{ marginLeft: "5px", marginTop: "5px" }}>
                  <CheckCircleIcon
                    style={{ color: "green", fontSize: "20px" }}
                  />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          // color="primary"
          type="submit"
          className={classes.submit}
        >
          {loading ? (
            <CircularProgress size={30} className={classes.loading} />
          ) : (
            <FormattedMessage id={MESSAGES.BUTTON.SENDRESET} />
          )}
        </Button>
        <Divider style={{ marginTop: "20px" }} />
      </form>
      <Grid
        container
        justify="space-between"
        direction="row"
        style={{ padding: "20px 20px 0px 20px" }}
      >
        <Grid item>
          <Link to="/" variant="body2" className={classes.linkPage}>
            <FormattedMessage id={MESSAGES.LINK.BACKTOLOGIN} />
          </Link>
        </Grid>
        <Grid item>
          <Link to="/" variant="body2" className={classes.linkPage}>
            <FormattedMessage id={MESSAGES.LINK.CONTACTSUPPORT} />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
