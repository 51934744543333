/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SocialMedia from "../components/socialMedia";
import Alert from "@material-ui/lab/Alert";
import passwordStrength from "../utils/passwordStrength";
import PasswordBar from "../components/passwordBar";
import { checkEmail, signup, socialSignup } from "../utils/request";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import debounce from "debounce-promise";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MESSAGES } from "../i18n/messages/constant";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: (props) =>
      props.primaryColor ? props.primaryColor : "#E62A43",
    color: (props) => (props.textColor ? props.textColor : "white"),
    fontWeight: "bolder",
    "&:hover, & .Mui-focusVisible": {
      backgroundColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62A43",
      filter: "brightness(80%)",
    },
  },
  linkPage: {
    color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    fontWeight: "bold",
    textDecoration: "none",
  },
  input: {
    "& label.Mui-focused": {
      color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62C46",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c4c4c4",
      },
      "&:hover fieldset": {
        borderColor: "#5b5b5b",
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.primaryColor ? props.primaryColor : "#E62C46",
      },
    },
  },
  title: {
    color: "grey",
    marginTop: "10px",
    fontSize: "15px",
    fontWeight: "bold",
  },
  errorText: {
    color: "red",
    marginLeft: "5px",
  },
  statusErrorText: {
    color: "red",
    textAlign: "center",
    marginTop: "10px",
  },
  socialErrorText: {
    color: "red",
    marginTop: "10px",
  },
  loading: {
    color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    height: "10px",
    width: "10px",
  },
  loadingButton: {
    color: (props) => (props.textColor ? props.textColor : "white"),
    height: "10px",
    width: "10px",
  },
}));

export default function RegisterPage({ history, config = {} }) {
  const classes = useStyles(config.style);
  const [showPass, setShowpass] = React.useState(false);
  const [passStrength, setPassStrength] = React.useState({});
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [socmed, setSocmed] = React.useState(null);
  // const [name, setName] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [loadingProcess, setLoadingProcess] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [errorPass, setErrorPass] = React.useState(false);
  const [errorSocial, setErrorSocial] = React.useState(false);

  React.useEffect(() => {
    // document.title = config.name + " - Register";

    const { state } = history.location;

    if (state) {
      setEmail(state.email);
      triggerCheckEmail(state.email);

      if (state.socmed) setSocmed(state.socmed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const clearErrors = () => {
    setErrorStatus(false);
    setErrorPass(false);
    setErrorSocial(false);
  };

  const handleContinue = async (event) => {
    event.preventDefault();
    clearErrors();

    if (passStrength.value <= 1) {
      setErrorPass(true);
    } else {
      setLoadingProcess(true);
      // const checkemail = await checkEmail(config.serverOidcUrl, email);
      // if (checkemail) {
      //   setErrorEmail(true);
      if (!errorEmail) {
        if (config.data.signupFields.length !== 0) {
          history.push({
            pathname: "/update",
            state: { email, password, socmed },
          });
        } else {
          const result = await (async () => {
            if (socmed) {
              const registerData = {
                provider: socmed.provider,
                oauthKey: socmed.oauthKey,
                email,
                password,
                extras: {},
              };
              return socialSignup(config.serverSubmitUrl, registerData);
            } else {
              const registerData = {
                email,
                password,
                extras: {},
              };
              return signup(config.serverSubmitUrl, registerData);
            }
          })();

          if (result.data.redirectTo) {
            window.location.assign(result.data.redirectTo);
          } else if (result.status === 400) {
            setErrorStatus(true);
            setErrorText(result.data.message);
          } else {
            setErrorStatus(true);
            setErrorText(
              <FormattedMessage id={MESSAGES.PAGES.REGISTER.ERROR.CLOSE} />
            );
          }
        }
      }
      setLoadingProcess(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowpass(!showPass);
  };

  const handlePassword = (e) => {
    const pass = e.target.value;
    setPassword(pass);
    const test = passwordStrength(pass);
    setPassStrength(test);
  };

  const handleEmail = async (e) => {
    setEmail(e.target.value);
    triggerCheckEmail(e.target.value);
  };

  const debounceLoadData = React.useCallback(debounce(checkEmail, 2000), []);

  const triggerCheckEmail = async (inputtedEmail) => {
    setloading(true);
    const test = await debounceLoadData(config.serverOidcUrl, inputtedEmail);
    setErrorEmail(test);
    setloading(false);
  };

  const handleOauthCallback = async (cbRes) => {
    clearErrors();

    if (cbRes.userExists === true) {
      setErrorSocial(true);
      setErrorText(
        <FormattedMessage
          id={MESSAGES.PAGES.REGISTER.ERROR.LINKED}
          values={{ provider: capitalize(cbRes.provider) }}
        />
      );
    } else {
      setSocmed({
        provider: cbRes.provider,
        oauthKey: cbRes.oauthKey,
      });
      setEmail(cbRes.email);
      triggerCheckEmail(cbRes.email);
      setPassword("");
    }
  };

  return (
    <div>
      <FormattedMessage
        id={MESSAGES.PAGES.REGISTER.TITLE}
        values={{ name: config.name }}
      >
        {(message) => {
          if (document.title !== message) {
            document.title = message;
          }

          return null;
        }}
      </FormattedMessage>
      <Grid container justify="center">
        <Grid item>
          <div className={classes.title}>
            <FormattedMessage id={MESSAGES.PAGES.REGISTER.HEADER} />
          </div>
        </Grid>
      </Grid>
      {socmed && (
        <Alert severity="info" style={{ marginTop: "10px" }}>
          <FormattedMessage
            id={MESSAGES.PAGES.REGISTER.ALERT}
            values={{
              provider: <strong>{capitalize(socmed.provider)}</strong>,
            }}
          />
        </Alert>
      )}
      {/* <input type="text" label="test" onChange={handleEmail} /> */}
      <form className={classes.form} onSubmit={handleContinue}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.input}
              fullWidth
              required
            >
              <InputLabel htmlFor="outlined-adornment-email">
                <FormattedMessage id={MESSAGES.PAGES.REGISTER.LABEL.EMAIL} />
              </InputLabel>
              <OutlinedInput
                type="text"
                name="email"
                label={
                  <FormattedMessage id={MESSAGES.PAGES.REGISTER.LABEL.EMAIL} />
                }
                id="outlined-adornment-email"
                error={errorEmail && !loading}
                value={email}
                onChange={(e) => handleEmail(e)}
                endAdornment={
                  <InputAdornment position="end">
                    {email !== "" &&
                      (loading ? (
                        <CircularProgress
                          className={classes.loading}
                          size={25}
                        />
                      ) : errorEmail ? (
                        <CancelIcon style={{ color: "red" }} />
                      ) : (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ))}
                  </InputAdornment>
                }
              />
            </FormControl>
            {errorEmail && email !== "" && !loading && (
              <span className={classes.errorText}>
                <FormattedMessage
                  id={MESSAGES.PAGES.REGISTER.ERROR.USEDEMAIL}
                />
              </span>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={name}
              onChange={e => setName(e.target.value)}
              id="name"
              label="Enter full name"
              name="name"
              className={classes.input}
            />
          </Grid> */}
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.input}
              fullWidth
              required
            >
              <InputLabel htmlFor="outlined-adornment-password">
                <FormattedMessage id={MESSAGES.PAGES.REGISTER.LABEL.PASSWORD} />
              </InputLabel>
              <OutlinedInput
                type={showPass ? "text" : "password"}
                name="password"
                label={
                  <FormattedMessage
                    id={MESSAGES.PAGES.REGISTER.LABEL.PASSWORD}
                  />
                }
                error={errorPass}
                id="outlined-adornment-password"
                value={password}
                onChange={(e) => handlePassword(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {errorPass && (
              <div className={classes.errorText}>
                <FormattedMessage
                  id={MESSAGES.PAGES.REGISTER.ERROR.MINPASSWORDSTR}
                />
              </div>
            )}
          </Grid>
        </Grid>
        {password.length !== 0 && <PasswordBar pass={passStrength} />}

        <Grid
          container
          justify="center"
          direction="row"
          style={{ marginTop: "20px" }}
        >
          <Grid item>
            <div style={{ color: "grey", textAlign: "justify" }}>
              <FormattedMessage id={MESSAGES.PAGES.REGISTER.LABEL.AGREEMENT} />
            </div>
          </Grid>
        </Grid>
        {errorStatus && (
          <div className={classes.statusErrorText}>{errorText}</div>
        )}
        <Button
          fullWidth
          variant="contained"
          type="submit"
          // color="primary"
          className={classes.submit}
        >
          {loadingProcess ? (
            <CircularProgress className={classes.loadingButton} size={25} />
          ) : (
            <FormattedMessage id={MESSAGES.BUTTON.CONTINUE} />
          )}
        </Button>
        {!socmed && (
          <SocialMedia config={config} onOauthCallback={handleOauthCallback} />
        )}
        {errorSocial && (
          <div className={classes.socialErrorText}>{errorText}</div>
        )}
        <Divider style={{ marginTop: "20px" }} />
      </form>
      <Grid
        container
        justify="center"
        direction="row"
        style={{ padding: "20px 20px 20px 20px" }}
      >
        <Grid item>
          <Link to="/" variant="body2" className={classes.linkPage}>
            <FormattedMessage id={MESSAGES.LINK.LOGIN} />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
