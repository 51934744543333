export const MESSAGES = {
  PAGES: {
    LOGIN: {
      HEADER: "pages.login.header",
      TITLE: "pages.login.title",
      ERROR: { WINDOW: "pages.login.error.window" },
      LABEL: {
        EMAIL: "pages.login.label.email",
        PASSWORD: "pages.login.label.password",
      },
    },
    AUTHACCESS: {
      HEADER: "pages.authAccess.header",
      TITLE: "pages.authAccess.title",
      MODAL: {
        HEADER: "pages.authAccess.modal.header",
        BODY: "pages.authAccess.modal.body",
      },
    },
    AUTHAPPLICATION: {
      HEADER: "pages.authApplications.header",
      TITLE: "pages.authApplications.title",
      BODY: "pages.authApplications.body",
      LIST: {
        NAME: {
          EMAIL: "pages.authApplications.list.name.email",
          ADDRESS: "pages.authApplications.list.name.address",
          PHONE: "pages.authApplications.list.name.phone",
        },
        DESC: {
          EMAIL: "pages.authApplications.list.desc.email",
          ADDRESS: "pages.authApplications.list.desc.address",
          PHONE: "pages.authApplications.list.desc.phone",
        },
      },
    },
    AUTHORIZE: {
      HEADER: "pages.authorize.header",
      TITLE: "pages.authorize.title",
      BODY: "pages.authorize.body",
      BODY2: "pages.authorize.body2",
    },
    CHANGEPWD: {
      HEADER: "pages.changePassword.header",
      TITLE: "pages.changePassword.title",
      ERROR: {
        INVALIDPWD: "pages.changePassword.error.invalidPwd",
      },
      LABEL: {
        CURRENTPWD: "pages.changePassword.label.currentPwd",
        NEWPWD: "pages.changePassword.label.newPwd",
      },
    },
    EDITPROFILE: {
      HEADER: "pages.editProfile.header",
      TITLE: "pages.editProfile.title",
      LABEL: {
        FULLNAME: "pages.editProfile.label.fullname",
        DOB: "pages.editProfile.label.dob",
        POB: "pages.editProfile.label.pob",
        ADDRESS: "pages.editProfile.label.address",
        ID: "pages.editProfile.label.citizenship ID",
        UPLOAD: "pages.editProfile.label.upload",
      },
    },
    EMAILVERIFIED: {
      HEADER: "pages.emailVerified.header",
      TITLE: "pages.emailVerified.title",
      STATUS: {
        FAIL: "pages.emailVerified.status.fail",
        SUCCESS: "pages.emailVerified.status.success",
      },
      MESSAGE: { SUCCESS: "pages.emailVerified.message.success" },
    },
    FORGOTPASSWORD: {
      HEADER: "pages.forgotPassword.header",
      TITLE: "pages.forgotPassword.title",
      LABEL: { EMAIL: "pages.forgotPassword.label.email" },
      MESSAGE: {
        ERROR: {
          FORMAT: "pages.forgotPassword.message.error.format",
          INVALID: "pages.forgotPassword.message.error.invalid",
        },
        SUCCESS: "pages.forgotPassword.success",
      },
    },
    LINKMEDIA: {
      HEADER: "pages.linkMedia.header",
      TITLE: "pages.linkMedia.title",
      ERROR: { LINKED: "pages.linkMedia.error.linked" },
      WARNING: { UNLINK: "pages.linkMedia.warning.unlink" },
    },
    NOAUTH: {
      TITLE: "pages.noAuth.title",
      HEADER: "pages.noAuth.header",
      BODY: "pages.noAuth.body",
    },
    PROFILE: {
      TITLE: "pages.profile.title",
      HEADER: "pages.profile.header",
    },
    REGISTER: {
      TITLE: "pages.register.title",
      HEADER: "pages.register.header",
      ERROR: {
        CLOSE: "pages.register.error.close",
        LINKED: "pages.register.error.linked",
        USEDEMAIL: "pages.register.error.usedEmail",
        MINPASSWORDSTR: "pages.register.error.minPasswordStr",
      },
      ALERT: "pages.register.alert",
      LABEL: {
        EMAIL: "pages.register.label.email",
        PASSWORD: "pages.register.label.password",
        AGREEMENT: "pages.register.label.agreement",
      },
    },
    SELECTACCOUNT: {
      HEADER: "pages.selectAccount.header",
    },
    UPDATE: {
      TITLE: "pages.update.title",
      HEADER: "pages.update.header",
      ERROR: { CLOSE: "pages.update.error.close" },
    },
    RESETPASSWORD: {
      TITLE: "pages.resetPassword.title",
      HEADER: "pages.resetPassword.header",
      LABEL: {
        NEWPASSWORD: "pages.resetPassword.newPassword",
        CONFIRMPASSWORD: "pages.resetPassword.confirmPassword",
      },
      ERROR: {
        MINPASSWORDSTR: "pages.register.error.minPasswordStr",
        PASSWORDNOTMATCH: "pages.resetPassword.passwordNotMatch",
      },
      SUCCESS: "pages.resetPassword.success",
    },
    VIEWACCOUNT: {
      TITLE: "pages.viewAccount.title",
      HEADER: "pages.viewAccount.header",
      LIST: {
        PROFILE: "pages.viewAccount.list.profile",
        CHANGEPASSWORD: "pages.viewAccount.list.changePassword",
        LINKSOCIALMEDIA: "pages.viewAccount.list.linkSocialMedia",
        AUTHORIZEDACCESS: "pages.viewAccount.list.authorizedAccess",
      },
      MODAL: { BODY: "pages.viewAccount.modal.body" },
    },
  },
  BUTTON: {
    LOGIN: "button.login",
    LOGOUT: "button.logout",
    CONTINUE: "button.continue",
    CANCEL: "button.cancel",
    REVOKE: "button.revoke",
    CONFIRM: "button.confirm",
    ALLOW: "button.allow",
    DENY: "button.deny",
    ACCEPT: "button.accept",
    UPDATE: "button.update",
    SENDRESET: "button.sendResetLink",
    GOAUTH: "button.goAuth",
    SAVEEDIT: "button.saveEdit",
    CANCELEDIT: "button.cancelEdit",
    EDITPROFILE: "button.editProfile",
    UNLINK: "button.unlink",
    CONTINUEAS: "button.continueAs",
    SWITCHACCOUNT: "button.switchAccount",
    CLOSEWINDOW: "button.closeWindow",
    SOCIALMEDIA: {
      CONTINUE: {
        GOOGLE: "button.socialMedia.continue.google",
        FACEBOOK: "button.socialMedia.continue.facebook",
        TWITTER: "button.socialMedia.continue.twitter",
      },
      LINKED: {
        GOOGLE: "button.socialMedia.linked.google",
        FACEBOOK: "button.socialMedia.linked.facebook",
        TWITTER: "button.socialMedia.linked.twitter",
      },
      LINK: {
        GOOGLE: "button.socialMedia.link.google",
        FACEBOOK: "button.socialMedia.link.facebook",
        TWITTER: "button.socialMedia.link.twitter",
      },
    },
  },
  LINK: {
    FORGOTPWD: "link.forgotpwd",
    CREATENEW: "link.createnew",
    TERMS: "link.terms",
    PRIVACYPOL: "link.privacypol",
    TERMSCON: "link.termscon",
    BACKTOLOGIN: "link.backtologin",
    CONTACTSUPPORT: "link.contactsupport",
    LOGIN: "link.login",
  },
};
