import React from "react";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

export default function Datetime({
  name,
  value,
  onDateChange,
  allowNull,
  clearValue,
  className,
  column
}) {
  const handleDateChange = date => {
    if (date) {
      const nameSave = column ? column : name;
      const utcFormat = moment(date)
        .utc()
        .format();
      onDateChange({ name: nameSave, value: utcFormat });
    }
  };

  return (
    <TextField
      id="date"
      label={name}
      fullWidth
      type="datetime-local"
      value={value}
      onChange={e => handleDateChange(e.target.value)}
      className={className}
      InputLabelProps={{
        shrink: true
      }}
      required={allowNull}
    />
  );
}
