import React from "react";
import TextField from "@material-ui/core/TextField";

export default function Float({
  name,
  value,
  onFloatChange,
  allowNull,
  className,
  column
}) {
  const handleFloatChange = event => {
    if (event.target.value === "") {
      onFloatChange({
        name: event.target.name,
        value: null
      });
    } else {
      onFloatChange({
        name: event.target.name,
        value: parseFloat(event.target.value)
      });
    }
  };
  return (
    <TextField
      id="outlined-textfield"
      label={name}
      fullWidth
      className={className}
      name={column}
      type="number"
      value={value}
      variant="outlined"
      onChange={handleFloatChange}
      required={allowNull}
    />
  );
}
