import React from "react";
import TextField from "@material-ui/core/TextField";

export default function Time({
  name,
  value,
  onTimeChange,
  allowNull,
  clearValue,
  className,
  column
}) {
  // React.useEffect(() => {
  //   if (value) {
  //     let date = moment().format("YYYY-MM-DD[T]");
  //     setMyTime(`${date}${value}`);
  //   } else {
  //     setMyTime(null);
  //   }
  // }, [value]);

  const handleTimeChange = event => {
    // if (value) {
    //   let date = new Date(value);
    //   let configTimezone = new Date(
    //     date.getTime() - date.getTimezoneOffset() * 60000
    //   );
    //   let strTime = configTimezone.toISOString();
    //   onTimeChange({ name, value: strTime.split("T")[1].substring(0, 8) });
    // }
    const nameSave = column ? column : name;
    onTimeChange({ nameSave, value: event.target.value });
  };

  return (
    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //   <KeyboardTimePicker
    //     margin="dense"
    //     inputVariant="outlined"
    //     id="date-picker-dialog"
    //     label={name}
    //     format="HH:mm:ss"
    //     value={myTime}
    //     open={open}
    //     onClose={() => {
    //       setOpen(false);
    //     }}
    //     onChange={handleTimeChange}
    //     KeyboardButtonProps={{
    //       "aria-label": "change date"
    //     }}
    //     InputProps={{
    //       readOnly: true,
    //       endAdornment: (
    //         <React.Fragment>
    //           {allowNull ? (
    //             <IconButton
    //               onClick={() => {
    //                 clearValue();
    //               }}
    //               style={{ margin: "-0.5em" }}
    //             >
    //               <Close />
    //             </IconButton>
    //           ) : null}
    //           <IconButton
    //             onClick={() => setOpen(true)}
    //             style={{ margin: "-0.5em" }}
    //           >
    //             <Event />
    //           </IconButton>
    //         </React.Fragment>
    //       )
    //     }}
    //     InputAdornmentProps={{
    //       position: "start",
    //       style: {
    //         display: "none"
    //       }
    //     }}
    //     required={allowNull === false ? true : false}
    //   />
    // </MuiPickersUtilsProvider>
    <TextField
      id="date"
      label={name}
      fullWidth
      type="time"
      value={value}
      onChange={e => handleTimeChange(e)}
      className={className}
      InputLabelProps={{
        shrink: true
      }}
      required={allowNull}
    />
  );
}
