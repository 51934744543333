import React from "react";
import TextField from "@material-ui/core/TextField";

export default function Decimal({
  name,
  value,
  onDecimalChange,
  allowNull,
  className,
  column
}) {
  const handleDecimalChange = event => {
    if (event.target.value === "") {
      onDecimalChange({
        name: event.target.name,
        value: null
      });
    } else {
      onDecimalChange({
        name: event.target.name,
        value: event.target.value
      });
    }
  };
  return (
    <TextField
      id="outlined-textfield"
      label={name}
      name={column}
      type="number"
      value={value}
      required={allowNull}
      fullWidth
      className={className}
      variant="outlined"
      onChange={handleDecimalChange}
    />
  );
}
