/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import Button from "@material-ui/core/Button";
// import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PasswordBar from "../components/passwordBar";
import passwordStrength from "../utils/passwordStrength";
import { reset } from "../utils/request";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { MESSAGES } from "../i18n/messages/constant";
import { FormattedMessage } from "react-intl";
import getParam from "../utils/getparam";
const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    backgroundColor: (props) =>
      props.primaryColor ? props.primaryColor : "#E62A43",
    color: (props) => (props.textColor ? props.textColor : "white"),
    fontWeight: "bolder",
    "&:hover, & .Mui-focusVisible": {
      backgroundColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62A43",
      filter: "brightness(80%)",
    },
  },
  cancel: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bolder",
    marginTop: "30px",
  },
  linkPage: {
    color: "#E62C46",
    fontWeight: "bold",
    textDecoration: "none",
  },
  input: {
    "& label.Mui-focused": {
      color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62C46",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c4c4c4",
      },
      "&:hover fieldset": {
        borderColor: "#5b5b5b",
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.primaryColor ? props.primaryColor : "#E62C46",
      },
    },
  },
  title: {
    color: "grey",
    marginTop: "10px",
    fontSize: "15px",
    fontWeight: "bold",
  },
  errorText: {
    color: "red",
    marginLeft: "5px",
  },
  success: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function resetPassword({ props, config = {} }) {
  const classes = useStyles(config.style);
  //   const [continueProcess, setContinue] = React.useState(false);
  const [showPass, setShowpass] = React.useState(false);
  const [passStrength, setPassStrength] = React.useState({});
  const [password, setPassword] = React.useState("");
  const [oldPass, setOldPass] = React.useState("");
  const [errorPass, setErrorPass] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [errorReset, setErrorReset] = React.useState(false);

  const handlerContinue = async (e) => {
    e.preventDefault();
    setErrorReset(false);

    const confirm = password === oldPass;
    const strength = passStrength.value > 1;

    if (strength) {
      setErrorPass(false);
    } else {
      setErrorPass(true);
    }

    if (confirm && strength) {
      const result = await reset(config.serverOidcUrl, {
        token: getParam("token"),
        password,
      });
      // console.log(result);
      if (result.message === "User password reset") {
        setPassword("");
        setOldPass("");
        setPassStrength({});
        setShowSuccess(true);
      } else if (result.error) {
        setErrorReset(true);
      }
    }
  };

  // React.useEffect(() => {
  //   document.title = config.name + " - Change Password";
  // }, [config.name]);

  const handleClickShowPassword = () => {
    setShowpass(!showPass);
  };

  const handlePassword = (e) => {
    const pass = e.target.value;
    setPassword(pass);
    const test = passwordStrength(pass);
    setPassStrength(test);
  };

  const handleOldPassword = (e) => {
    const pass = e.target.value;
    setOldPass(pass);
  };

  const closeWindow = () => {
    window.open("", "_self").close();
  };

  return (
    <div>
      <FormattedMessage
        id={MESSAGES.PAGES.RESETPASSWORD.TITLE}
        values={{ name: config.name }}
      >
        {(message) => {
          if (document.title !== message) {
            document.title = message;
          }

          return null;
        }}
      </FormattedMessage>
      <Grid container justify="center">
        <Grid item>
          <div className={classes.title}>
            <FormattedMessage id={MESSAGES.PAGES.RESETPASSWORD.HEADER} />
          </div>
        </Grid>
      </Grid>
      <form className={classes.form} onSubmit={(e) => handlerContinue(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.input}
              fullWidth
              required
            >
              <InputLabel htmlFor="password">
                <FormattedMessage
                  id={MESSAGES.PAGES.RESETPASSWORD.LABEL.NEWPASSWORD}
                />
              </InputLabel>
              <OutlinedInput
                type={showPass ? "text" : "password"}
                id="password"
                value={password}
                error={errorPass}
                disabled={showSuccess}
                onChange={(e) => handlePassword(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={160}
              />
            </FormControl>
            {errorPass && (
              <div className={classes.errorText}>
                <FormattedMessage
                  id={MESSAGES.PAGES.RESETPASSWORD.ERROR.MINPASSWORDSTR}
                />
              </div>
            )}
            {password.length !== 0 && <PasswordBar pass={passStrength} />}
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.input}
              fullWidth
              required
            >
              <InputLabel htmlFor="outlined-adornment-oldpassword">
                <FormattedMessage
                  id={MESSAGES.PAGES.RESETPASSWORD.LABEL.CONFIRMPASSWORD}
                />
              </InputLabel>
              <OutlinedInput
                type={showPass ? "text" : "password"}
                id="outlined-adornment-oldpassword"
                value={oldPass}
                disabled={showSuccess}
                error={password !== oldPass}
                onChange={(e) => handleOldPassword(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={180}
              />
            </FormControl>
            {password !== oldPass && (
              <div className={classes.errorText}>
                <FormattedMessage
                  id={MESSAGES.PAGES.RESETPASSWORD.ERROR.PASSWORDNOTMATCH}
                />
              </div>
            )}
          </Grid>
        </Grid>
        {showSuccess && (
          <div className={classes.success}>
            <div>
              <FormattedMessage id={MESSAGES.PAGES.RESETPASSWORD.SUCCESS} />
            </div>
            <div style={{ marginLeft: "5px", marginTop: "5px" }}>
              <CheckCircleIcon style={{ color: "green", fontSize: "20px" }} />
            </div>
          </div>
        )}
        {errorReset && (
          <div className={classes.success}>
            <div className={classes.errorText}>
              Invalid reset password token
            </div>
          </div>
        )}
        {!showSuccess && (
          <Button
            fullWidth
            variant="contained"
            type="submit"
            className={classes.submit}
          >
            <FormattedMessage id={MESSAGES.BUTTON.UPDATE} />
          </Button>
        )}
      </form>
      {showSuccess && (
        <Button
          fullWidth
          variant="contained"
          className={classes.cancel}
          onClick={() => closeWindow()}
        >
          <FormattedMessage id={MESSAGES.BUTTON.CLOSEWINDOW} />
        </Button>
      )}
    </div>
  );
}
