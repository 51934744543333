/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import Button from "@material-ui/core/Button";
// import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PasswordBar from "../components/passwordBar";
import passwordStrength from "../utils/passwordStrength";
import { changePassword } from "../utils/request";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MESSAGES } from "../i18n/messages/constant";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    backgroundColor: (props) =>
      props.primaryColor ? props.primaryColor : "#E62A43",
    color: (props) => (props.textColor ? props.textColor : "white"),
    fontWeight: "bolder",
    "&:hover, & .Mui-focusVisible": {
      backgroundColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62A43",
      filter: "brightness(80%)",
    },
  },
  cancel: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bolder",
  },
  linkPage: {
    color: "#E62C46",
    fontWeight: "bold",
    textDecoration: "none",
  },
  input: {
    "& label.Mui-focused": {
      color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62C46",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c4c4c4",
      },
      "&:hover fieldset": {
        borderColor: "#5b5b5b",
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.primaryColor ? props.primaryColor : "#E62C46",
      },
    },
  },
  title: {
    color: "grey",
    fontSize: "15px",
    fontWeight: "bold",
    marginTop: "10px",
    textAlign: "center",
  },
  title2: {
    color: "grey",
    fontSize: "15px",
    textAlign: "center",
    marginTop: "5px",
  },
  errorText: {
    color: "red",
    marginLeft: "5px",
  },
  success: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingButton: {
    color: (props) => (props.textColor ? props.textColor : "white"),
    height: "10px",
    width: "10px",
  },
}));

export default function changePass({ history, config = {} }) {
  const classes = useStyles(config.style);
  //   const [continueProcess, setContinue] = React.useState(false);
  const [showPass, setShowpass] = React.useState(false);
  const [passStrength, setPassStrength] = React.useState({});
  const [password, setPassword] = React.useState("");
  const [oldPass, setOldPass] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [errorPass, setErrorPass] = React.useState(false);

  const handlerContinue = async (event) => {
    event.preventDefault();
    const strength = passStrength.value > 1;
    setLoading(true);
    if (strength) {
      setErrorPass(false);
    } else {
      setErrorPass(true);
    }

    setError(false);
    setSuccess(false);

    if (strength) {
      const data = {
        oldPassword: oldPass,
        newPassword: password,
      };
      const result = await changePassword(config.serverAccountUrl, data);
      console.log(result);

      if (result.message === "User password changed") {
        setSuccess(true);
        setPassword("");
        setOldPass("");
      } else {
        setError(true);
        setErrorMessage(
          result.data.message === "'oldPassword' is not valid" ? (
            <FormattedMessage id={MESSAGES.PAGES.CHANGEPWD.ERROR.INVALIDPWD} />
          ) : (
            result.data.message
          )
        );
      }
    }
    setLoading(false);
  };

  // React.useEffect(() => {
  //   document.title = config.name + " - Change Password";
  // }, [config.name]);

  const handleClickShowPassword = () => {
    setShowpass(!showPass);
  };

  const handlePassword = (e) => {
    const pass = e.target.value;
    setPassword(pass);
    const test = passwordStrength(pass);
    setPassStrength(test);
  };

  const handleOldPassword = (e) => {
    const pass = e.target.value;
    setOldPass(pass);
    setSuccess(false);
  };

  return (
    <div>
      <FormattedMessage
        id={MESSAGES.PAGES.CHANGEPWD.TITLE}
        values={{ name: config.name }}
      >
        {(message) => {
          if (document.title !== message) {
            document.title = message;
          }

          return null;
        }}
      </FormattedMessage>
      <Grid container justify="center" direction="column">
        <Grid item>
          <div className={classes.title}>
            <FormattedMessage id={MESSAGES.PAGES.CHANGEPWD.HEADER} />
          </div>
        </Grid>
        <Grid item>
          <div className={classes.title2}>
            {history.location.state ? history.location.state.data.email : ""}
          </div>
        </Grid>
      </Grid>
      <form className={classes.form} onSubmit={(e) => handlerContinue(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.input}
              fullWidth
              required
            >
              <InputLabel htmlFor="outlined-adornment-oldpassword">
                <FormattedMessage
                  id={MESSAGES.PAGES.CHANGEPWD.LABEL.CURRENTPWD}
                />
              </InputLabel>
              <OutlinedInput
                type={showPass ? "text" : "password"}
                id="outlined-adornment-oldpassword"
                value={oldPass}
                error={error}
                onChange={(e) => handleOldPassword(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={180}
              />
            </FormControl>
            {error && <div className={classes.errorText}>{errorMessage}</div>}
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.input}
              fullWidth
              required
            >
              <InputLabel htmlFor="password">
                <FormattedMessage id={MESSAGES.PAGES.CHANGEPWD.LABEL.NEWPWD} />
              </InputLabel>
              <OutlinedInput
                type={showPass ? "text" : "password"}
                id="password"
                value={password}
                error={errorPass}
                onChange={(e) => handlePassword(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={160}
              />
            </FormControl>
            {errorPass && (
              <div className={classes.errorText}>Minimal Fair for password</div>
            )}
          </Grid>
        </Grid>
        {password.length !== 0 && <PasswordBar pass={passStrength} />}

        {success && (
          <div className={classes.success}>
            <div>Your password changed ! </div>
            <div style={{ marginLeft: "5px", marginTop: "5px" }}>
              <CheckCircleIcon style={{ color: "green", fontSize: "20px" }} />
            </div>
          </div>
        )}
        <Button
          fullWidth
          variant="contained"
          className={classes.submit}
          type="submit"
        >
          {loading ? (
            <CircularProgress className={classes.loadingButton} size={25} />
          ) : (
            <FormattedMessage id={MESSAGES.BUTTON.CONTINUE} />
          )}
        </Button>
      </form>
      <Button
        fullWidth
        variant="contained"
        className={classes.cancel}
        onClick={() => history.goBack()}
      >
        <FormattedMessage id={MESSAGES.BUTTON.CANCEL} />
      </Button>
    </div>
  );
}
