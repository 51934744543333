import React from "react";
import TextField from "@material-ui/core/TextField";

export default function BigInteger({
  name,
  value,
  onBigIntChange,
  allowNull,
  className,
  column
}) {
  const handleBigIntChange = event => {
    if (event.target.value === "") {
      onBigIntChange({
        name: event.target.name,
        value: null
      });
    } else {
      onBigIntChange({
        name: event.target.name,
        value: parseInt(event.target.value)
      });
      // console.log(BigInt(event.target.value));
    }
  };
  return (
    <TextField
      id="outlined-textfield"
      label={name}
      name={column ? column : name}
      fullWidth
      // type="number"
      value={value}
      variant="outlined"
      className={className}
      onChange={handleBigIntChange}
      required={allowNull}
    />
  );
}
