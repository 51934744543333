import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { MESSAGES } from "../i18n/messages/constant";
import { FormattedMessage } from "react-intl";
import getParam from "../utils/getparam";
const useStyles = makeStyles((theme) => ({
  grant: {
    backgroundColor: (props) =>
      props.primaryColor ? props.primaryColor : "#E62C46",
    color: (props) => (props.textColor ? props.textColor : "white"),
    "&:hover, & .Mui-focusVisible": {
      backgroundColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62A43",
      filter: "brightness(80%)",
    },
  },
  title: {
    color: "grey",
    marginTop: "10px",
    fontSize: "15px",
    fontWeight: "bold",
  },
  text: {
    color: "grey",
    marginTop: "10px",
    fontSize: "15px",
    textAlign: "center",
  },
  email: {
    color: "grey",
  },
  name: {
    fontWeight: "bolder",
  },
  gridList: {
    display: "flex",
    flexDirection: "row",
    margin: "0px 10px 10px 35px",
    alignItems: "center",
    fontSize: "12px",
  },
}));

export default function emailVerified({ props, config = {} }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles(config.style);
  // const [config2, setConfig2] = React.useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {}, []);

  return (
    <div>
      <FormattedMessage
        id={MESSAGES.PAGES.EMAILVERIFIED.TITLE}
        values={{ name: config.name }}
      >
        {(message) => {
          if (document.title !== message) {
            document.title = message;
          }

          return null;
        }}
      </FormattedMessage>
      <Grid container justify="center">
        <Grid item>
          <div className={classes.title}>
            <FormattedMessage id={MESSAGES.PAGES.EMAILVERIFIED.HEADER} />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        style={{ marginTop: "100px", textAlign: "center" }}
        direction="column"
      >
        <Grid item>
          {getParam("confirm") === "failed" ? (
            <CancelIcon style={{ fontSize: "80px", color: "red" }} />
          ) : (
            <CheckCircleOutlineIcon
              style={{ fontSize: "80px", color: "green" }}
            />
          )}
        </Grid>
        <Grid item style={{ fontSize: "30px", fontWeight: "bold" }}>
          {getParam("confirm") === "failed" ? (
            <FormattedMessage id={MESSAGES.PAGES.EMAILVERIFIED.STATUS.FAIL} />
          ) : (
            <FormattedMessage
              id={MESSAGES.PAGES.EMAILVERIFIED.STATUS.SUCCESS}
            />
          )}
        </Grid>
        <Grid item>
          {getParam("confirm") === "failed" ? (
            config.data.message
          ) : (
            <FormattedMessage
              id={MESSAGES.PAGES.EMAILVERIFIED.MESSAGE.SUCCESS}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
