import sheriff from "password-sheriff";

const upperCase = sheriff.charsets.upperCase;
const lowerCase = sheriff.charsets.lowerCase;
const numbers = sheriff.charsets.numbers;
const specialCharacters = sheriff.charsets.specialCharacters;

const policies = {
  none: {
    length: { minLength: 1 }
  },
  low: {
    length: { minLength: 6 }
  },
  fair: {
    length: { minLength: 8 },
    containsAtLeast: {
      atLeast: 2,
      expressions: [lowerCase, upperCase, numbers, specialCharacters]
    }
  },
  good: {
    length: { minLength: 8 },
    containsAtLeast: {
      atLeast: 3,
      expressions: [lowerCase, upperCase, numbers, specialCharacters]
    }
  },
  excellent: {
    length: { minLength: 10 },
    containsAtLeast: {
      atLeast: 3,
      expressions: [lowerCase, upperCase, numbers, specialCharacters]
    },
    identicalChars: { max: 2 }
  }
};

const excellent = new sheriff.PasswordPolicy(policies.excellent);
const good = new sheriff.PasswordPolicy(policies.good);
const fair = new sheriff.PasswordPolicy(policies.fair);
const low = new sheriff.PasswordPolicy(policies.low);
// const none = new sheriff.PasswordPolicy(policies.none);

//example
// none = a
// low = abcdef
// fair = abcdef12V
// good =

export default function passwordStrength(pass) {
  if (excellent.check(pass)) {
    return { value: 4, level: "Excellent" };
  } else if (good.check(pass)) {
    return { value: 3, level: "Good" };
  } else if (fair.check(pass)) {
    return { value: 2, level: "Fair" };
  } else if (low.check(pass)) {
    return { value: 1, level: "Low" };
  } else {
    return { value: 0, level: "" };
  }
}
