/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import BackupIcon from "@material-ui/icons/Backup";
// import { register } from "../utils/request";
import moment from "moment";
import { signup, socialSignup } from "../utils/request";

import BigInt from "../components/inputs/BigInteger";
import Boolean from "../components/inputs/Boolean";
import Date from "../components/inputs/Date";
import DateTime from "../components/inputs/Datetime";
import Decimal from "../components/inputs/Decimal";
import Float from "../components/inputs/Float";
import Interger from "../components/inputs/Integer";
import Jsonb from "../components/inputs/Jsonb";
import String from "../components/inputs/String";
import Text from "../components/inputs/Text";
import UUID from "../components/inputs/Uuid";
import Time from "../components/inputs/Time";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MESSAGES } from "../i18n/messages/constant";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: (props) =>
      props.primaryColor ? props.primaryColor : "#E62A43",
    color: (props) => (props.textColor ? props.textColor : "white"),
    fontWeight: "bolder",
    "&:hover, & .Mui-focusVisible": {
      backgroundColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62A43",
      filter: "brightness(80%)",
    },
  },
  cancel: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
  },
  linkPage: {
    color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    fontWeight: "bold",
    textDecoration: "none",
  },
  input: {
    "& label.Mui-focused": {
      color: (props) => (props.primaryColor ? props.primaryColor : "#E62C46"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: (props) =>
        props.primaryColor ? props.primaryColor : "#E62C46",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c4c4c4",
      },
      "&:hover fieldset": {
        borderColor: "#5b5b5b",
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.primaryColor ? props.primaryColor : "#E62C46",
      },
    },
  },
  title: {
    color: "grey",
    marginTop: "10px",
    fontSize: "15px",
    fontWeight: "bold",
  },
  inputFile: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
  inputLabel: {
    backgroundColor: "red",
  },
  uploadLabel: {
    color: "grey",
  },
  upload: {
    height: "180px",
    border: "1px solid #bfbfbf",
    borderRadius: "5px",
    display: "flex",
    backgroundColor: "#EFEFEF",
    justifyContent: "center",
    alignItems: "center",
  },
  email: {
    color: "grey",
    marginTop: "30px",
    fontWeight: "bold",
    paddingLeft: "5px",
  },
  errorStatusText: {
    color: "red",
    textAlign: "center",
    marginTop: "10px",
  },
  uploadIcon: {
    color: (props) => (props.primaryColor ? props.primaryColor : "#D10000"),
    fontSize: "80px",
  },
  loadingButton: {
    color: (props) => (props.textColor ? props.textColor : "white"),
    height: "10px",
    width: "10px",
  },
}));

export default function update({ history, config = {} }) {
  const classes = useStyles(config.style);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [socmed, setSocmed] = React.useState(null);
  const [errorText, setErrorText] = React.useState("");
  const [errorStatus, setErrorStatus] = React.useState(false);
  // const [fullname, setFullname] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  // const [urlUpload, setUrlUpload] = React.useState("");
  const [values, setValues] = React.useState({});
  // const listExtras = [
  //   {
  //     name: "String",
  //     type: "string",
  //     defaultValue: "ini string",
  //     allowNull: false
  //   },
  //   {
  //     name: "UUID",
  //     type: "uuid",
  //     defaultValue: "9wwiniewnei28823nndd",
  //     allowNull: false
  //   },
  //   {
  //     name: "Text",
  //     type: "text",
  //     defaultValue: "ini text area",
  //     allowNull: false
  //   },
  //   {
  //     name: "Big Interger",
  //     type: "bigInterger",
  //     defaultValue: 200,
  //     allowNull: false
  //   },
  //   {
  //     name: "Boolean",
  //     type: "boolean",
  //     defaultValue: true,
  //     allowNull: false
  //   },
  //   {
  //     name: "Decimal",
  //     type: "decimal",
  //     defaultValue: null,
  //     allowNull: false
  //   },
  //   {
  //     name: "Float",
  //     type: "float",
  //     defaultValue: null,
  //     allowNull: false
  //   },
  //   {
  //     name: "Interger",
  //     type: "interger",
  //     defaultValue: null,
  //     allowNull: false
  //   },
  //   {
  //     name: "Jsonb",
  //     type: "jsonb",
  //     defaultValue: null,
  //     allowNull: false
  //   },
  //   {
  //     name: "Date",
  //     type: "date",
  //     defaultValue: null,
  //     allowNull: false
  //   },
  //   {
  //     name: "Datetime",
  //     type: "datetime",
  //     defaultValue: null,
  //     allowNull: false
  //   },
  //   {
  //     name: "Time",
  //     type: "time",
  //     defaultValue: null,
  //     allowNull: false
  //   }
  // ];

  React.useEffect(() => {
    const { state } = history.location;

    if (state) {
      // setFullname(state.fullname);
      setEmail(state.email);
      setPassword(state.password);
      setSocmed(state.socmed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // document.title = "Update Profile";
    const list = config.data.signupFields;
    let col = {};
    // eslint-disable-next-line array-callback-return
    list.map((e) => {
      if (e.defaultValue) {
        col = { ...col, [e.name]: e.defaultValue };
      } else {
        col = { ...col, [e.name]: null };
      }

      if (e.type === "date" && e.defaultValue === null) {
        col = { ...col, [e.name]: moment().format("YYYY-MM-DD") };
      } else if (e.type === "time" && e.defaultValue === null) {
        col = { ...col, [e.name]: moment().format("HH:mm") };
      } else if (e.type === "datetime" && e.defaultValue === null) {
        col = {
          ...col,
          [e.name]: moment().format("YYYY-MM-DD[T]HH:mm"),
        };
      } else if (e.type === "boolean") {
        if (e.allowNull === true) {
          col = {
            ...col,
            [e.name]: e.defaultValue,
          };
        } else {
          col = {
            ...col,
            [e.name]:
              e.defaultValue === true || e.defaultValue === null ? true : false,
          };
        }
      }
    });
    console.log(col);

    setValues(col);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);

    const result = await (async () => {
      if (socmed) {
        const registerData = {
          provider: socmed.provider,
          oauthKey: socmed.oauthKey,
          email,
          password,
          extras: { ...values },
        };
        return socialSignup(config.serverSubmitUrl, registerData);
      } else {
        const registerData = {
          email,
          password,
          extras: { ...values },
        };
        return signup(config.serverSubmitUrl, registerData);
      }
    })();

    if (result.data) {
      history.push({
        pathname: "/login",
        state: { registerSuccess: true },
      });
    } else if (result.error.status === "400") {
      setErrorStatus(true);
      setErrorText(result.error.title);
    } else {
      setErrorStatus(true);
      setErrorText(<FormattedMessage id={MESSAGES.PAGES.UPDATE.ERROR.CLOSE} />);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    history.goBack();
    console.log(history);
  };

  // const handleUpload = e => {
  //   setUrlUpload(URL.createObjectURL(e.target.files[0]));
  // };

  const handleChange = ({ name, value }) => {
    // console.log(name);
    // console.log(value);
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div>
      <FormattedMessage
        id={MESSAGES.PAGES.UPDATE.TITLE}
        values={{ name: config.name }}
      >
        {(message) => {
          if (document.title !== message) {
            document.title = message;
          }

          return null;
        }}
      </FormattedMessage>
      <Grid container justify="center">
        <Grid item>
          <div className={classes.title}>
            {/* Please complete your profile */}
            <FormattedMessage id={MESSAGES.PAGES.UPDATE.HEADER} />
          </div>
        </Grid>
      </Grid>
      <div className={classes.email}>{email}</div>
      <form className={classes.form} onSubmit={(e) => handleUpdate(e)}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={fullname}
              id="fullname"
              label="Full Name"
              name="fullname"
              onChange={e => handleName(e)}
              className={classes.input}
            />
          </Grid> */}

          {config.data.signupFields.map(({ name, type, label, allowNull }) => (
            <Grid item xs={12} key={name}>
              {type === "bigInterger" && (
                <BigInt
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || 0}
                  onBigIntChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {type === "boolean" && (
                <Boolean
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || true}
                  onBooleanChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {type === "date" && (
                <Date
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || "02-02-2020"}
                  onDateChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {type === "datetime" && (
                <DateTime
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || "2017-05-24T10:30"}
                  onDateChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {type === "time" && (
                <Time
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || "00:00"}
                  onTimeChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {type === "decimal" && (
                <Decimal
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || "0"}
                  onDecimalChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {type === "float" && (
                <Float
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || "0"}
                  onFloatChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {type === "interger" && (
                <Interger
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || "0"}
                  onIntChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {(type === "json" || type === "jsonb") && (
                <Jsonb
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || { name: "lorem", address: "other" }}
                  onJsonbChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {type === "string" && (
                <String
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || ""}
                  onChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {type === "text" && (
                <Text
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || ""}
                  onChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
              {type === "uuid" && (
                <UUID
                  name={label}
                  column={name}
                  className={classes.input}
                  value={values[name] || ""}
                  onChange={({ name, value }) => {
                    handleChange({ name, value });
                  }}
                  allowNull={!allowNull}
                />
              )}
            </Grid>
          ))}

          {/* <Grid item xs={12}>
            <Grid item>
              <div className={classes.uploadLabel}>Citizenship ID</div>
            </Grid>
            <Grid item className={classes.upload}>
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                {urlUpload === "" ? (
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <BackupIcon className={classes.uploadIcon} />
                    </div>
                    <div style={{ textAlign: "center" }}>Click to upload</div>
                  </div>
                ) : (
                  <img
                    alt="img-ktp"
                    style={{
                      height: "160px",
                      width: "95%",
                      marginTop: "5px",
                      marginLeft: "10px"
                    }}
                    src={urlUpload}
                  />
                )}
              </label>
              <input
                type="file"
                name="file"
                id="file"
                accept="image/png, image/jpeg"
                onChange={e => handleUpload(e)}
                style={{
                  opacity: 0,
                  height: "1px",
                  width: "1px",
                  zIndex: "-1",
                  cursor: "pointer"
                }}
              />
            </Grid>
          </Grid>*/}
        </Grid>
        {errorStatus && (
          <div className={classes.errorStatusText}>{errorText}</div>
        )}
        <Button
          fullWidth
          variant="contained"
          // color="primary"
          type="submit"
          className={classes.submit}
        >
          {loading ? (
            <CircularProgress className={classes.loadingButton} size={25} />
          ) : (
            <FormattedMessage id={MESSAGES.BUTTON.CONTINUE} />
          )}
        </Button>
        <Button
          fullWidth
          variant="contained"
          // color="primary"
          className={classes.cancel}
          onClick={() => handleCancel()}
        >
          <FormattedMessage id={MESSAGES.BUTTON.CANCEL} />
        </Button>
      </form>
    </div>
  );
}
