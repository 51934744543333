export const localeReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_LOCALE":
      return (state = action.value);
    case "CHANGE_AND_SAVE_LOCALE":
      localStorage.setItem("locale", JSON.stringify(action.value));
      return (state = action.value);
    default:
      return state;
  }
};
